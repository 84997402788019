import type {
  BrandComplaintRankResponse,
  CaseCollectiveResponse,
  CaseSuccessResponse,
  CollectiveComplainResponse,
  CollectiveDetailResponse,
  CollectiveRelativeComplaintResponse,
  ComplaintBannerResponse,
  ComplaintDetailResponse,
  ComplaintSearchParams,
  ComplaintSearchResponse,
  FactoryRankResponse,
  GetUserComplaintParams,
  HeaderCompliantResponse,
  HotCollectiveRankResponse,
  HotComplaintRankResponse,
  MediaComplaintPageResponse,
  NewCompleteComplaintResponse,
  PrecinctComplainPageResponse,
  PrecinctComplainResponse,
  PrecinctVendorPageResponse,
  PublishComplaintParams,
  PublishCompleteComplaintResponse,
  RelativeComplaintRankResponse,
  SeriesTabListResponse,
  SurveyComplaintPageParams,
  SurveyComplaintPageResponse,
  UserComplaintDetailResponse,
  VendorSynthesizeTopResponse,
  complainScoreItem,
} from './types'
import type { FetchResponseType, PaginationParams, PaginationResponse } from '@/server/request/types'
import { useApiFetch } from '@/server/request'
import type { SERIES_GRADE_ENUM } from '@/shared/types'

/**
 * 头部投诉滚动列表
 * @returns FetchResponseType<HeaderCompliantResponse[]>
 */
export async function getHeaderCompliantListApi() {
  return useApiFetch<FetchResponseType<HeaderCompliantResponse[]>>('/webportal/base/indexNewComplain', {
    method: 'GET',
  })
}

/**
 * 首页和口碑也页头部车系Tab列表
 * @param params
 * @returns
 */
export async function getSeriesTabListApi(params: {
  seriesGradeEnum?: SERIES_GRADE_ENUM[]
  pageSize?: number
  version?: string
}) {
  return useApiFetch<FetchResponseType<SeriesTabListResponse>>('/webportal/base/home/homeHotSeries', {
    method: 'GET',
    params,
  })
}

/**
 * 最新完成投诉列表
 * @returns FetchResponseType<NewCompleteComplaintResponse[]>
 */
export async function getNewCompleteComplaintListApi() {
  return useApiFetch<FetchResponseType<NewCompleteComplaintResponse[]>>('/webportal/base/home/complainProcessingList', {
    method: 'GET',
  })
}

/**
 * 最新完成投诉列表
 * @returns FetchResponseType<NewCompleteComplaintResponse[]>
 */
export async function getPrecinctComplainPageApi(precinctCode: string | number, params: PaginationParams) {
  const code = Number(precinctCode)

  return useApiFetch<FetchResponseType<PaginationResponse<PrecinctComplainPageResponse<typeof code>[]>>>(
    `/webportal/base/complain/precinctComplainPage/${code}`,
    {
      method: 'GET',
      params,
    },
  )
}

/**
 * 集体投诉案例列表
 * @returns FetchResponseType<CaseCollectiveResponse[]>
 */
export async function getCollectiveCaseListApi() {
  return useApiFetch<FetchResponseType<CaseCollectiveResponse[]>>('/webportal/base/home/caseCollection', {
    method: 'GET',
  })
}

/**
 * 成功案例列表
 * @returns FetchResponseType<CaseSuccessResponse[]>
 */
export async function getSuccessCaseListApi() {
  return useApiFetch<FetchResponseType<CaseSuccessResponse[]>>('/webportal/base/home/successfulComplain', {
    method: 'GET',
  })
}

/**
 * 一追到底
 * @returns FetchResponseType<SurveyComplaintPageResponse[]>
 */
export async function getSurveyComplaintPageApi(params: SurveyComplaintPageParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<SurveyComplaintPageResponse[]>>>('/webportal/base/home/complainSurveyBottomLs', {
    method: 'GET',
    params,
  })
}

/**
 * 视频投诉
 */
export async function getVideoComplaintPageApi<T extends 'video'>(params: PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<MediaComplaintPageResponse<T>[]>>>('/webportal/base/home/complainVideoPage', {
    method: 'GET',
    params,
  })
}
/**
 * 图片投诉
 */
export async function getPictureComplaintPageApi<T extends 'picture'>(params: PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<MediaComplaintPageResponse<T>[]>>>('/webportal/base/home/complainImgPage', {
    method: 'GET',
    params,
  })
}

/**
 * 投诉首页banner数据
 * @returns FetchResponseType<ComplaintBannerData>
 */
export async function getComplaintBannerDataApi() {
  return useApiFetch<FetchResponseType<ComplaintBannerResponse>>('/webportal/base/complain/findHotRecomComplain', {
    method: 'GET',
  })
}

/**
 * 厂商
 */
export async function getPrecinctVendorPageApi(params: PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<PrecinctVendorPageResponse[]>>>('/webportal/base/complain/precinctVendorPage', {
    method: 'GET',
    params,
  })
}

export async function complaintDetailApi(id: string | number) {
  return useApiFetch<FetchResponseType<ComplaintDetailResponse>>(`/webportal/base/complain/detail/${id}`, {
    method: 'GET',
  })
}

export async function getComplaintSearchApi(params: ComplaintSearchParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<ComplaintSearchResponse[]>>>(`/webportal/base/search/complain`, {
    method: 'GET',
    params,
    setting: {
      repetition: 'abort',
      splicingParameters: false,
    },
  })
}

// 全部投诉
export async function getComplaintAllApi(params: { complainTypeCode: number } & PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<PrecinctComplainResponse[]>>>(`/webportal/base/complain/allComplain`, {
    method: 'GET',
    params,
  })
}

/**
 * 集体投诉
 * @param params.complainTypeCode 1最新/2最多综/3最多评/4热门
 * @returns
 */
export async function getComplaintCollectiveApi(params: { complainTypeCode: number } & PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<CollectiveComplainResponse[]>>>(`/webportal/base/collective/collectivePage`, {
    method: 'GET',
    params,
  })
}

// 多次投诉
export async function getCompliantAllMoreApi(params: { complainTypeCode: number } & PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<PrecinctComplainResponse[]>>>(`/webportal/base/complain/allMore`, {
    method: 'GET',
    params,
  })
}

export async function getCollectiveDetailApi(collectiveId: string | number) {
  return useApiFetch<FetchResponseType<CollectiveDetailResponse>>(`/webportal/base/collective/detail/${collectiveId}`, {
    method: 'GET',
  })
}

export async function getCollectiveDetailAllImageApi(params: { collectiveId: string | number } & PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<string[]>>>(`/webportal/base/collective/detail/imgPage`, {
    method: 'GET',
    params,
  })
}

export async function getCollectiveRelativeComplaintListApi(params: { collectiveId: string | number } & PaginationParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<CollectiveRelativeComplaintResponse[]>>>(`/webportal/base/collective/relevancyComplainPage`, {
    method: 'GET',
    params,
  })
}

export async function getUserComplaintDetailApi(params: GetUserComplaintParams) {
  return useApiFetch<FetchResponseType<PaginationResponse<UserComplaintDetailResponse[]>>>(`/webportal/base/complain/userRelatedComplain`, {
    method: 'GET',
    params,
  })
}

export async function publishComplaintApi(params: PublishComplaintParams) {
  return useApiFetch<FetchResponseType<number>>(`/webportal/car/complainPublish`, {
    method: 'POST',
    body: params,
  })
}

export async function publishCompleteComplaintListApi(params: {
  brandId: number
  seriesId: number
  cityId: number
  provinceId: number
}) {
  return useApiFetch<FetchResponseType<PublishCompleteComplaintResponse[][]>>(`/webportal/base/complainPublishSuccess`, {
    method: 'GET',
    params,
  })
}

/* ************************************************************** */
/* ****************************RANK****************************** */
/* ************************************************************** */
/**
 * 热门投诉
 */
export async function getHotComplaintRankApi(timeScopeEnum: 'DAY' | 'WEEK' | 'MONTH') {
  return useApiFetch<FetchResponseType<HotComplaintRankResponse[]>>('/webportal/base/ranking/complain/hot', {
    method: 'GET',
    params: { timeScopeEnum },
  })
}

/**
 * 厂商投诉量排行榜
 */
export async function getBrandComplaintRankApi() {
  return useApiFetch<FetchResponseType<BrandComplaintRankResponse[]>>('/webportal/base/ranking/complain/brand', {
    method: 'GET',
  })
}

/**
 * 厂商排行
 */
export async function getFactoryRankApi(code: 'REPLY' | 'SCORE') {
  return useApiFetch<FetchResponseType<FactoryRankResponse[]>>('/webportal/base/ranking/complain/vendor', {
    method: 'GET',
    params: { code },
  })
}

/**
 * 厂商综合排行
 */
export async function getVendorSynthesizeTopApi() {
  return useApiFetch<FetchResponseType<VendorSynthesizeTopResponse>>('/webportal/base/ranking/complain/vendorSynthesizeTop', {
    method: 'GET',
  })
}

export async function getHotCollectiveRankApi() {
  return useApiFetch<FetchResponseType<HotCollectiveRankResponse[]>>('/webportal/base/home/caseCollection', {
    method: 'GET',
  })
}

export async function getRelativeComplaintRankApi(params: { seriesId: string | number }) {
  return useApiFetch<FetchResponseType<RelativeComplaintRankResponse[]>>('/webportal/base/ranking/complain/correlation', {
    method: 'GET',
    params,
  })
}

export async function collectiveHotApi(params: { title?: string, size: number }) {
  return useApiFetch<FetchResponseType<CollectiveDetailResponse[]>>('webportal/base/collective/hot', {
    method: 'GET',
    params,
  })
}

export async function getComplainScoreApi(collectiveId: string | number) {
  return useApiFetch<FetchResponseType<complainScoreItem[]>>(`webportal/base/collective/complainScore/${collectiveId}`, {
    method: 'GET',
  })
}

export async function getCollectiveStats(collectiveId: string | number) {
  return useApiFetch<FetchResponseType<{ date: string, total: number }[]>>(`webportal/base/collective/stats/${collectiveId}`, {
    method: 'GET',
  })
}
